// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#temp-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/app/utils/temp-container.scss"],"names":[],"mappings":"AAAA;EAKE,kBAAA;EACA,MAAA;EACA,WAAA;EAEA,aAAA;AAJF","sourcesContent":["#temp-container {\n  // Position this at the very top of the screen. Most things won't care\n  // (they're absolutely positioned themselves, but @textcomplete doesn't know\n  // how to position itself within a positioning context that isn't the\n  // document.\n  position: absolute;\n  top: 0;\n  width: 100%;\n  // Make sure things in the temp container display above others\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
